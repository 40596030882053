
import { defineComponent, onMounted, ref } from "vue";
import { ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";
import { hideModal } from "@/core/helpers/dom";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/";

interface NewTicketData {
  title: string;
  priority: string;
  department_id: string;
  message: string;
}

export default defineComponent({
  name: "create-ticket-modal",
  emits: ["refreshTickets"],
  setup(props, { emit }) {
    const { t, te } = useI18n();

    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const store = useStore();
    const errorsBag = ref({});
    const formRef = ref<null | HTMLFormElement>(null);
    const ticketModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const departments = ref();
    const targetData = ref<NewTicketData>({
      title: "",
      priority: "",
      department_id: "",
      message: "",
    });

    const rules = ref({
      title: [
        {
          required: true,
          message: translate("Please enter the ticket title"),
          trigger: "blur",
        },
      ],
      priority: [
        {
          required: true,
          message: translate("Please select your ticket priority"),
          trigger: "blur",
        },
      ],
      department_id: [
        {
          required: true,
          message: translate("Please select the relevant section"),
          trigger: "blur",
        },
      ],
      message: [
        {
          required: true,
          message: translate("Please enter message"),
          trigger: "blur",
        },
      ],
    });

    onMounted(() => {
      getDepartments();
    });

    const getDepartments = () => {
      ApiService.get("ticket/departments")
        .then(({ data }) => {
          departments.value = data.data.departments;
        })
        .catch(() => {
          // errors.value = response.data;
          // ElNotification({
          //   title: "Info",
          //   message: "خطا در بارگزاری لیست دپارتمان ها",
          //   type: "info",
          // });
        });
    };

    const submit = () => {
      if (!formRef.value) return;

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          let data = {
            title: targetData.value.title,
            priority: targetData.value.priority,
            department_id: targetData.value.department_id,
            message: targetData.value.message,
          };

          ApiService.post("ticket/create", data)
            .then(() => {
              emit("refreshTickets");
              hideModal(ticketModalRef.value);
            })
            .catch(({ response }) => {
              store.commit(Mutations.SET_ERROR, response.data);
            })
            .finally(() => (loading.value = false));
        }
      });
    };

    return {
      targetData,
      submit,
      loading,
      formRef,
      rules,
      ticketModalRef,
      departments,
      errorsBag,
      translate,
    };
  },
});
