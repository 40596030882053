
import { defineComponent, onMounted, ref } from "vue";
import TicketsFilter from "@/views/pages/other/ticket/component/TicketsFilter.vue";
import CreateTicketModal from "@/views/pages/other/ticket/component/CreateTicketModal.vue";
import ShowTicketBodyModal from "@/views/pages/other/ticket/component/ShowTicketBodyModal.vue";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import { statusColor } from "@/core/services/HelpersFun";
import UserAvatar from "@/components/UserAvatar.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "kt-table-my-ticket-list",
  components: {
    TicketsFilter,
    CreateTicketModal,
    ShowTicketBodyModal,
    UserAvatar,
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const route = useRoute();
    const adminMode = ref(false);
    const loading = ref(true);
    const list = ref();
    const pagination = ref();
    const page = ref(1);
    const statisticsData = ref();
    const selectedId = ref(null);

    const getItems = (data: any = {}) => {
      data.page = page.value;
      let prefixEndpoint = "";
      if (adminMode.value) prefixEndpoint = "admin/";
      ApiService.query(`${prefixEndpoint}ticket`, { params: data })
        .then(({ data }) => {
          loading.value = false;
          list.value = data.data.tickets;
          pagination.value = data.data.tickets.pagination;
          statisticsData.value = data.data.statistics;
        })
        .catch(() => {
          // ElNotification({
          //   title: "Info",
          //   message: "خطا در بارگزاری لیست تیکت ها",
          //   type: "info",
          // });
        });
    };

    const permission = ref();
    const userType = ref();

    onMounted(() => {
      setTimeout(() => {
        let per: any = localStorage.getItem("permissions");
        permission.value = JSON.parse(per);
        let user: any = localStorage.getItem("user_info");
        userType.value = JSON.parse(user).type;
      }, 3000);

      if (route.path === "/management/tickets") adminMode.value = true;
      else adminMode.value = false;

      setTimeout(getItems, Math.floor(Math.random() * (3 - 1 + 1) + 1) * 1000);
      setCurrentPageBreadcrumbs(translate("tickets"), []);
    });

    const handleSizeChange = (val: number) => {
      return;
    };
    const handleCurrentChange = (val: number) => {
      page.value = val;
      getItems();
    };

    // const connectToSocket = async () => {
    //   let userId = JwtService.getUserId();
    //   if (JwtService.getUserId()) {
    //     window.Echo.private("user." + userId).listen(
    //       ".refresh_tickets",
    //       (e) => {
    //         getItems();
    //       }
    //     );
    //   }
    // };

    return {
      list,
      loading,
      pagination,
      statisticsData,
      getItems,
      handleCurrentChange,
      handleSizeChange,
      selectedId,
      adminMode,
      statusColor,
      translate,
      route,
      permission,
      userType,
    };
  },
});
