
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { translate } from "@/core/services/HelpersFun";
interface Filter {
  department: number;
  status: string;
  q: string;
}

export default defineComponent({
  name: "tickets-filter",
  components: {},
  emits: ["search"],
  setup(props, { emit }) {
    const departments = ref();
    const data = ref<Filter>({
      department: 1,
      status: "",
      q: "",
    });

    onMounted(() => {
      getDepartments();
    });

    const getDepartments = () => {
      ApiService.get("ticket/departments")
        .then(({ data }) => {
          departments.value = data.data.departments;
        })
        .catch(({ response }) => {
          // ElNotification({
          //   title: "Info",
          //   message: "خطا در بارگزاری لیست دپارتمان ها",
          //   type: "info",
          // });
        });
    };

    const reset = () => {
      data.value = {
        department: 0,
        status: "",
        q: "",
      };
      emit("search", {});
    };

    const search = () => {
      let params = { ...data.value };

      emit("search", params);
    };

    return {
      data,
      departments,
      search,
      reset,
      translate,
    };
  },
});
