import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = ["element-loading-text"]
const _hoisted_3 = { class: "table-responsive" }
const _hoisted_4 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 custom-loading-svg" }
const _hoisted_5 = { class: "fw-bolder text-muted bg-light" }
const _hoisted_6 = { class: "text-first min-w-100px" }
const _hoisted_7 = { class: "text-first min-w-100px" }
const _hoisted_8 = { class: "text-first min-w-100px" }
const _hoisted_9 = { class: "text-first min-w-100px" }
const _hoisted_10 = { class: "text-first min-w-100px" }
const _hoisted_11 = { class: "text-first min-w-50px" }
const _hoisted_12 = {
  type: "button",
  class: "btn btn-sm btn-primary btn-icon btn-active-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_13 = { class: "svg-icon svg-icon-2" }
const _hoisted_14 = { class: "text-hover-primary mb-1 fs-7" }
const _hoisted_15 = { class: "d-flex align-items-center" }
const _hoisted_16 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_17 = { class: "text-dark fw-bolder text-hover-primary mb-1 fs-7" }
const _hoisted_18 = { class: "text-muted fs-7" }
const _hoisted_19 = { class: "d-flex align-items-center" }
const _hoisted_20 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_21 = { class: "text-dark text-hover-primary mb-1 fs-7" }
const _hoisted_22 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_23 = { class: "text-start" }
const _hoisted_24 = ["disabled", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_TicketsFilter = _resolveComponent("TicketsFilter")!
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_Date = _resolveComponent("Date")!
  const _component_Empty = _resolveComponent("Empty")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_CreateTicketModal = _resolveComponent("CreateTicketModal")!
  const _component_ShowTicketBodyModal = _resolveComponent("ShowTicketBodyModal")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "card-body",
        "element-loading-text": _ctx.translate('loading')
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("table", _hoisted_4, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_5, [
                _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.translate("Title")), 1),
                _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.translate("user")), 1),
                _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.translate("Priority")), 1),
                _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.translate("The relevant section")), 1),
                _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.translate("Condition")), 1),
                _createElementVNode("th", _hoisted_11, [
                  _createTextVNode(_toDisplayString(_ctx.translate("the operation")) + " ", 1),
                  _createElementVNode("button", _hoisted_12, [
                    _createElementVNode("span", _hoisted_13, [
                      _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen004.svg" })
                    ])
                  ]),
                  _createVNode(_component_TicketsFilter, { onSearch: _ctx.getItems }, null, 8, ["onSearch"])
                ])
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", null, [
                    _createElementVNode("span", _hoisted_14, _toDisplayString(item.title), 1)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_UserAvatar, {
                        avatar: item.creator.picture,
                        size: "40px",
                        id: item.creator.id
                      }, null, 8, ["avatar", "id"]),
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("span", _hoisted_17, _toDisplayString(item.creator.first_name) + " " + _toDisplayString(item.creator.last_name), 1),
                        _createElementVNode("span", _hoisted_18, [
                          _createVNode(_component_Date, {
                            date: item.created_at
                          }, null, 8, ["date"])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("span", {
                      class: _normalizeClass(["d-inline", `badge badge-light-${_ctx.statusColor(
                      item.priority
                    )} fs-7`])
                    }, _toDisplayString(_ctx.translate(item.priority)), 3)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("span", _hoisted_21, _toDisplayString(item.department.name), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("span", {
                        class: _normalizeClass(`badge badge-light-${_ctx.statusColor(item.status)}`)
                      }, _toDisplayString(_ctx.translate(item.status)), 3)
                    ])
                  ]),
                  _createElementVNode("td", _hoisted_23, [
                    _createElementVNode("button", {
                      disabled: 
                      !_ctx.permission?.includes('ticket_view') &&
                      _ctx.userType?.includes('admin')
                    ,
                      onClick: ($event: any) => (_ctx.selectedId = item.ticket_number),
                      "data-bs-toggle": "modal",
                      "data-bs-target": "#kt_modal_show_ticket_body",
                      class: "btn btn-sm btn-outline btn-outline-dashed btn-active-light-primary me-1 mb-1"
                    }, _toDisplayString(_ctx.translate("view")), 9, _hoisted_24)
                  ])
                ]))
              }), 128))
            ])
          ])
        ]),
        (_ctx.list && _ctx.list.length === 0)
          ? (_openBlock(), _createBlock(_component_Empty, { key: 0 }))
          : _createCommentVNode("", true)
      ], 8, _hoisted_2)), [
        [_directive_loading, _ctx.loading]
      ]),
      (_ctx.pagination && _ctx.pagination.total_pages > 1)
        ? (_openBlock(), _createBlock(_component_el_pagination, {
            key: 0,
            pagerCount: 4,
            background: "",
            layout: "prev, pager, next",
            class: "mt-4 mb-4 text-center direction-rtl",
            total: _ctx.pagination.total,
            currentPage: _ctx.pagination.current_page,
            "page-size": _ctx.pagination.per_page,
            onSizeChange: _ctx.handleSizeChange,
            onCurrentChange: _ctx.handleCurrentChange
          }, null, 8, ["total", "currentPage", "page-size", "onSizeChange", "onCurrentChange"]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.adminMode)
      ? (_openBlock(), _createBlock(_component_CreateTicketModal, {
          key: 0,
          onRefreshTickets: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getItems()))
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ShowTicketBodyModal, {
      ticketId: _ctx.selectedId,
      admin: _ctx.adminMode,
      onRefreshTickets: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getItems()))
    }, null, 8, ["ticketId", "admin"])
  ], 64))
}